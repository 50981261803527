/* eslint-disable arrow-body-style */
import React from "react";
import { withPrefix, Link } from "gatsby";
import Helmet from "react-helmet";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";
import Tooltip from "react-tooltip-lite";
import SEO from "../components/SEO";
import Layout from "../layouts/index";
import OverviewHero from "../components/OverviewHero";

const Home = ({ ...props }) => {
  return (
    <Layout bodyClass="page-home" {...props}>
      <SEO title="Home" />
      <Helmet>
        <meta
          name="description"
          content="Awarding $1.5M in combined prizes to solutions accelerating postsecondary success for parents."
        />
      </Helmet>
      <OverviewHero />

      <div className="container px-2 py-3 py-md-4  ">
        <div className="row justify-content-start">
          <div className="col-12">
            <div className="mb-2">
              <h2 className="font-weight-bolder">The Rise Prize</h2>
              <p className="py-1">
                The Rise Prize will make 14 awards totalling $1.5M to solutions
                driving economic mobility and well-being for{" "}
                <Tooltip
                  className="tooltip-target"
                  tagName="span"
                  content="Student parents are individuals who are parents in postsecondary education of any type."
                >
                  student parents.
                </Tooltip>
              </p>
              <p className="py-1">
                The Prize was created by&nbsp;
                <a
                  className="text-blue text-underline"
                  href="https://www.imaginablefutures.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    trackCustomEvent({
                      category: "External Link",
                      action: "Click",
                      label: `Imaginable Futures`,
                    });
                  }}
                >
                  Imaginable Futures
                </a>
                —a venture of The Omidyar Group—and&nbsp;
                <a
                  className="text-blue text-underline"
                  href="https://www.luminafoundation.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    trackCustomEvent({
                      category: "External Link",
                      action: "Click",
                      label: `Lumina Foundation`,
                    });
                  }}
                >
                  Lumina Foundation
                </a>
                , along with a group of leading&nbsp;
                <Link
                  className="text-blue text-underline"
                  to="/our-partners"
                  onClick={() => {
                    trackCustomEvent({
                      category: "Overview",
                      action: "Click",
                      label: `Partner Organziations`,
                    });
                  }}
                >
                  partner organizations
                </Link>
                , to leverage direct investments in innovative solutions
                supporting student parents.
              </p>
            </div>
          </div>
          <div className="col-12">
            <p>
              We believe that postsecondary success for parents is crucial in
              improving educational outcomes for entire families and supporting
              the journeys of millions of families to economic mobility. We are
              also now facing unprecedented uncertainty as we adjust to new
              realities as a result of COVID-19. Student parents are among the
              most impacted by the global pandemic, facing childcare
              disruptions, and unpredictability at school and with work.
            </p>
            <p className="callout text-blue font-weight-bold">
              Postsecondary success is defined as traditional degrees and
              non-traditional credential, certificate, workforce development,
              and alternative college pathways programs, that leads to economic
              mobility and overall well-being.
            </p>
          </div>
          <div className="col-12">
            <p className="py-1">
              If your company, program, or organization is working to support
              and empower student parents, or you want to learn how your
              solution can be tailored to accelerate the postsecondary success
              of student parents, apply for the Rise Prize by May 17!
            </p>
            <div className="overview-callout">
              <h4>By applying for the Rise Prize you could:</h4>
              <ul className="ul-blue">
                <li>
                  Raise awareness of your solution and get exposure to funders
                  and potential partners
                </li>
                <li>
                  Strengthen your idea with support from mentors and build
                  community with fellow innovators; and as a finalist, be paired
                  with an advisor to sharpen your product pitch
                </li>
                <li>
                  Win $50,000 as an early stage applicant, $100,000 as a Risers’
                  Choice awardee, or $200,000 as a mature stage applicant
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container py-3">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="">
              <h2 className="font-weight-bolder mb-2">Who Should Apply?</h2>
              <div className="">
                <p>
                  The Prize is open to impact-driven changemakers: companies,
                  programs, and organizations of any type or stage in the US
                  working on solutions directly supporting student parent
                  postsecondary success. This includes changemakers who are
                  seeking to add additional products or solutions to accelerate
                  the success of student parents.
                </p>
                <h4 className="text-blue font-weight-bolder pt-3 pt-md-4">
                  Applicant Type
                </h4>
                <p>
                  We welcome applicants working on innovative solutions to
                  directly impact postsecondary success for student parents at
                  scale, including:
                </p>
                <ul className="ul-blue">
                  <li>Non-profit organizations</li>
                  <li>For-profit companies, including edtech solutions</li>
                  <li>
                    Higher education institutions, including departments or
                    teams within higher education institutions; academic
                    institution associations; or alternative career pathways
                    programs, including workforce programs, credentialing
                    programs, and bootcamps
                  </li>
                </ul>
                <p>
                  Equity and inclusion is a central theme of the Rise Prize. We
                  strive to ensure that applicants of varying sizes, sectors,
                  and backgrounds have an equal chance of success.
                </p>
                <h4 className="text-blue font-weight-bolders pt-3 pt-md-4">
                  Solution Type
                </h4>
                <p>
                  The Rise Prize is designed to increase the number of
                  innovative solutions driving postsecondary success for student
                  parents. There will be three types of awards made through a
                  single application process:
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 mt-0 mt-sm-2 mx-1 mx-md-7">
            <div className="row justify-content-center">
              <div className="stage">
                <div className="stage-header">
                  <img
                    src={withPrefix("/illustrations/early-stage.svg")}
                    alt="Early Stage"
                  />
                  <h4 className="font-weight-bold">Early Stage</h4>
                </div>
                <div className="stage-content">
                  <p>
                    New ideas and solutions anchored in initial customer
                    validation (user testing or pilots, market research, or a
                    plan for customer validation).
                  </p>
                  <p>This could include:</p>
                  <ul className="ul-blue">
                    <li>
                      Pre-seed and seed startups that have developed a plan for
                      customer validation and research
                    </li>
                    <li>
                      Established organizations exploring new ideas and
                      solutions for student parents
                    </li>
                    <li>
                      Academic institutions and social ventures at testing or
                      pilot phases
                    </li>
                  </ul>
                  <div className="stage-info-box">
                    <p className="mb-0">
                      8 winners from the early stage category will be awarded
                      <span className="money-award font-alfa text-blue">
                        {" "}
                        $50,000{" "}
                      </span>{" "}
                      each.
                    </p>
                  </div>
                </div>
              </div>
              <div className="stage">
                <div className="stage-header">
                  <img
                    src={withPrefix("/illustrations/mature-stage.svg")}
                    alt="Mature Stage"
                  />
                  <h4 className="font-weight-bold">Mature Stage</h4>
                </div>
                <div className="stage-content">
                  <p>
                    Defined by solutions provided by organizations that have
                    strong conviction about the business or organization’s
                    ability to serve student parents and are looking to take
                    that work to scale. For venture-backed startups, this means
                    Series A and beyond.
                  </p>
                  <p>These solutions should have:</p>
                  <ul className="ul-blue">
                    <li>
                      Demonstrated product/market fit and strength of the market
                    </li>
                    <li>Measured impact on customers or end users</li>
                  </ul>
                  <div className="stage-info-box">
                    <p className="mb-0">
                      5 winners from the mature stage category will be awarded
                      <span className="money-award font-alfa text-blue">
                        {" "}
                        $200,000{" "}
                      </span>{" "}
                      each.
                    </p>
                  </div>
                </div>
              </div>
              <div className="stage choice-award">
                <div className="stage-header">
                  <img
                    src={withPrefix("/illustrations/choice-award.svg")}
                    alt="Riser Choice Award"
                  />
                  <h4 className="font-weight-bold">“Risers’ Choice Prize”</h4>
                </div>
                <div className="stage-content">
                  <p className="">
                    Awarded to one winner in either the Early Stage or Mature
                    Stage for
                    <span className="money-award text-blue font-alfa">
                      {" "}
                      $100,000{" "}
                    </span>
                    by our student parent judges.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="app-timeline" className="container py-4 py-md-6">
        <div className="row">
          <div className="col-12">
            <h2 className="font-weight-bolder mb-2 text-center">
              Application Timeline
            </h2>
          </div>
          <div className="col-12 d-none d-md-block">
            <img
              className="w-100"
              src={withPrefix("/illustrations/timeline.svg")}
              alt="application timeline"
            />
          </div>
          <div className="col-12 d-md-none">
            <img
              className="w-100"
              src={withPrefix("/illustrations/timeline-mobile.svg")}
              alt="application timeline"
            />
          </div>
        </div>
      </div>
      <div className="bg-light-green">
        <div className="container">
          <div className="row">
            <div className="col-12 process-metrics my-5">
              <h2 className="font-weight-bolder mb-3">
                What We’re Looking For
              </h2>
              <p>
                Solutions that will have a direct impact on the postsecondary
                success of student parents—whether your solution is focused on
                these outcomes or others, we want to hear from you. Those
                solutions could include: flexible courseware, original
                outcomes-based financing solutions, innovative childcare support
                models, community building or completion coaching—any type of
                solution designed to accelerate student parent success.
              </p>
              <div className="row">
                <div className="insight-c col-12 col-md-6 col-lg-4 px-3 px-md-1">
                  <img
                    src={withPrefix("about-risers/6C-childcare.svg")}
                    alt=""
                  />
                  <h5>CHILDCARE</h5>
                  <p>
                    Student parents do meaningfully better when there is access
                    to quality, affordable childcare solutions.
                  </p>
                </div>
                <div className="insight-c col-12 col-md-6 col-lg-4 px-3 px-md-1">
                  <img
                    src={withPrefix("about-risers/6C-community-support.svg")}
                    alt=""
                  />
                  <h5>COMMUNITY OF SUPPORT</h5>
                  <p>
                    Student parents benefit from support services/mentoring,
                    well-being, and access to social capital.
                  </p>
                </div>
                <div className="insight-c col-12 col-md-6 col-lg-4 px-3 px-md-1">
                  <img
                    src={withPrefix("about-risers/6C-completion-time.svg")}
                    alt=""
                  />
                  <h5>COMPLETION TIME</h5>
                  <p>
                    Student parents need solutions that are competency-based and
                    allow for completion faster or in more incremental ways.
                  </p>
                </div>
                <div className="insight-c col-12 col-md-6 col-lg-4 px-3 px-md-1">
                  <img
                    src={withPrefix("about-risers/6C-convenience-first.svg")}
                    alt=""
                  />
                  <h5>CONVENIENCE FIRST</h5>
                  <p>
                    Student parents need solutions that are flexible and adapted
                    to their “shredded time” schedule. This includes using
                    virtual and online courses to enhance the learning
                    environment, as well as a combination of virtual and
                    in-person coaching to assist with persistence.
                  </p>
                </div>
                <div className="insight-c col-12 col-md-6 col-lg-4 px-3 px-md-1">
                  <img src={withPrefix("about-risers/6C-cost.svg")} alt="" />
                  <h5>COST</h5>
                  <p>
                    Student parents require solutions that are affordable given
                    other budget constraints with children.
                  </p>
                </div>
                <div className="insight-c col-12 col-md-6 col-lg-4 px-3 px-md-1">
                  <img
                    src={withPrefix(
                      "about-risers/6C-credential-connection.svg",
                    )}
                    alt=""
                  />
                  <h5>CREDENTIAL / CONNECTION TO CAREER</h5>
                  <p>
                    Student parents need access to economic mobility through a
                    credential and increased connection to their job/career.
                    This includes programs that offer competency-based education
                    focused on the skills and competencies that employers want
                    and need.
                  </p>
                </div>
              </div>
              <div className="judge-criteria">
                <div className="judge-criteria__head">
                  <h4>Judging Criteria</h4>
                </div>
                <div className="judge-criteria__content">
                  <div className="row mx-1">
                    <div className="col-12">
                      <p className="py-1">
                        <strong>
                          Prize submissions will be judged on four important
                          components for success. These components are:
                        </strong>
                      </p>
                      <ul>
                        <li className="font-weight-bolder">Depth:</li>
                        <li>
                          The solution has the potential to impact postsecondary
                          success for student parents reached. Depth will be
                          measured by the actual or expected change in outcomes
                          for student parents impacted by the solution.
                        </li>
                        <ul className="ul-black px-md-2 pt-1">
                          <li>
                            Ability to increase postsecondary completion for
                            student parents
                          </li>
                          <li>
                            Ability to increase economic mobility for student
                            parents
                          </li>
                          <li>
                            Ability to positively impact well-being for student
                            parents
                          </li>
                        </ul>
                      </ul>
                      <ul>
                        <li className="font-weight-bolder ">Reach:</li>
                        <li>
                          The solution has the potential to scale and reach many
                          student parents. Reach will be measured by the
                          applicant’s intent and ability to scale their
                          solution.
                        </li>
                      </ul>
                      <ul>
                        <li className="font-weight-bolder ">Influence:</li>
                        <li>
                          The solution has the potential to influence others—for
                          example policymakers, community leaders, and other
                          innovators—towards greater impact for student parents.
                          Measured by the innovativeness and differentiation of
                          the solution, and the likelihood of replication in new
                          geographies and markets.
                        </li>
                      </ul>
                      <ul>
                        <li className="font-weight-bolder ">Feasibility:</li>
                        <li>
                          The applicant team is likely to be successful in
                          achieving their goals. Measured by the
                          organization&apos;s business model and/or fundraising
                          approach and the team&apos;s demonstrated track
                          record.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
